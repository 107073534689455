import { defineStore } from 'pinia';

export const useVolatility = defineStore('volatility', {
    state: () => ({
        refs: null,
        dataTable: null,
    }),
    getters: {
        localization(state) {
            return state.refs?.localization[Object.keys(state.refs.localization)[0]];
        },
        pager(state) {
            return state.dataTable.pager;
        },
        columns(state) {
            return state.dataTable.columns;
        },
        records(state) {
            return state.dataTable.records;
        },
    },
});